$color-primary: #024fa5;

.page-home.page-container {
  .section-container.guideline-container {
    min-height: 100vh;
    flex-direction: column;
    display: flex;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      text-align: center;
    }
    .guideline-view {
      padding: 20px 60px 20px 60px;
    }
    .guideline_section-title-container {
      text-align: center;
      .guideline_section-title {
        display: inline-block;
        margin-right: 24px;
        font-weight: 600;
        font-size: 30px;
        text-transform: uppercase;
        color: $color-primary;
      }
    }
    .edit-action-buttons {
      text-align: right;
      margin: 10px;
      .save-action {
        margin-left: 10px;
      }
      .cancel-action {
      }
    }
    .guideline-editor {
      .editor-wrapper.rdw-editor-wrapper {
        .editor-box {
          border: 1px solid lightsteelblue;
          min-height: 100vh;
          padding: 20px 60px 20px 60px;
        }
      }
    }
  }
}
